import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { SkeletonTheme } from "react-loading-skeleton";
// loading skeleton theme
import { Provider } from "react-redux";

import { IntercomProvider } from "@/utils/intercom/IntercomProvider";

import { wrapper } from "@/store/store";

if (typeof window !== "undefined") {
  // checks that we are client-side
  if (
    process.env.NODE_ENV === "production" &&
    !process.env.NEXT_PUBLIC_POSTHOG_HOST
  ) {
    throw new Error("NEXT_PUBLIC_POSTHOG_HOST is not set");
  }

  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
    api_host:
      process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    debug: true,
    capture_pageview: true, // Ensure pageviews are captured
    autocapture: true, // Enable autocapture for clicks, etc.
    loaded: posthog => {
      if (process.env.NODE_ENV === "development") posthog.debug(); // debug mode in development
    },
    session_recording: {
      maskTextSelector: ".ph-no-capture"
    }
  });
}

// A super-simple mock of a redux store
const ProviderWrapper = ({ children, ...rest }: any) => {
  const { store } = wrapper.useWrappedStore(rest);

  // Global configuration for SkeletonTheme from react-loading-skeleton library
  // https://github.com/dvtng/react-loading-skeleton
  const skeletonThemeConfig = {
    color: "rgb(208, 213, 221)", // Change the skeleton color here
    highlightColor: "rgba(242, 244, 247, 1)", // Change the highlight color here
    duration: 1.5 // Change the animation speed here (in seconds)
    // Add more configuration options as needed
  };

  return (
    <Provider store={store}>
      <IntercomProvider>
        <PostHogProvider client={posthog}>
          <SkeletonTheme {...skeletonThemeConfig}>{children}</SkeletonTheme>
        </PostHogProvider>
      </IntercomProvider>
    </Provider>
  );
};

export default ProviderWrapper;
