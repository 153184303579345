import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { getCookie, parseJwt } from "@/utils/cookies";

import { Cookie } from "@/store/services/user";

export type AuthInfo = {
  isAuthenticated: boolean;
  sessionInfo: Cookie | null;
};

export const initialState: AuthInfo = {
  isAuthenticated: false,
  sessionInfo: null
};

export const testInitialState: AuthInfo = {
  isAuthenticated: true,
  sessionInfo: {
    email: "provider@test.com",
    first: "Landon",
    is_admin: false,
    is_biller: false,
    is_ma: false,
    is_patient: false,
    is_provider: true,
    last: "Keough",
    perms: 4472688747740943,
    practice_id: 1,
    suffix: null,
    title: null,
    user_id: 1,
    version: 1
  } as Cookie
};

export const testAuthSlice = createSlice({
  name: "auth",
  initialState: testInitialState,
  reducers: {
    setIsAuthenticated: (state: AuthInfo, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
      if (!state.isAuthenticated) {
        state.sessionInfo = null;
      } else {
        const cookie = getCookie("X-Pario-Auth");

        state.sessionInfo = parseJwt(cookie as string) as Cookie;
      }
    },
    setSessionInfo: (state: AuthInfo, action: PayloadAction<Cookie>) => {
      state.isAuthenticated = true;
      state.sessionInfo = action.payload;
    }
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated: (state: AuthInfo, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
      if (!state.isAuthenticated) {
        state.sessionInfo = null;
      } else {
        const cookie = getCookie("X-Pario-Auth");
        if (cookie === undefined) return;

        state.sessionInfo = parseJwt(cookie) as Cookie;
      }
    }
  }
});

export const { setIsAuthenticated } = authSlice.actions;

export default authSlice.reducer;
