/* Global Store Configuration */
/* External */
import {
  Action,
  configureStore,
  Middleware,
  ThunkAction,
  Store
} from "@reduxjs/toolkit";
import { createWrapper, Context } from "next-redux-wrapper";

/* Local */

// slices
import wizardReducer from "@/components/wizard/wizardSlice";
import gridReducer from "@/components/datagrid/datagridSlice";
import tabReducer from "@/components/tabbed/tabSlice";
import drawerReducer from "@/components/drawer/drawerSlice";
import toastReducer from "@/components/toastTrough/toastSlice";
import modalReducer from "@/components/modal/modalSlice";
import flowReducer from "@/components/flows/flowSlice";
import snapshotReducer from "@/components/snapshot/snapshotSlice";
import taskReducer from "@/components/providerDash/providerDashSlice";
import patientsReducer from "@/components/patients/slice";
import calendarReducer from "@/components/scheduling/calendars/calendarSlice";
import authReducer, {
  testAuthSlice,
  initialState as initialAuthState
} from "@/components/auth/slice";
import mobileReducer from "@/components/mobile/mobileSlice";
import billingReducer from "@/components/billing/billingSlice";
import insuranceReducer from "@/components/forms/insuranceSlice";
import fileExplorerReducer from "@/components/fileExplorer/fileExplorerSlice";
import labFlowsheetReducer from "@/components/labFlowSheet/slice";
import conversationReducer from "@/components/messagingCenter/conversationSlice";
import { backendSlice } from "@/components/api/apiSlice";

const AUTH_LOCAL_STORAGE_KEY = "parioAuth";
let persistedAuthState = initialAuthState;
const localStorageMiddleware: Middleware =
  ({ getState }) =>
  next =>
  action => {
    const result = next(action);

    localStorage.setItem(
      AUTH_LOCAL_STORAGE_KEY,
      JSON.stringify(getState().auth)
    );

    return result;
  };

if (typeof window !== "undefined") {
  persistedAuthState = JSON.parse(
    localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || "{}"
  );
}

// use Redux Toolkit configureStore to create global store
export const store = configureStore({
  preloadedState: {
    auth: persistedAuthState
  },
  reducer: {
    wizard: wizardReducer,
    auth: authReducer,
    dataGrid: gridReducer,
    tabMenu: tabReducer,
    drawer: drawerReducer,
    toast: toastReducer,
    modal: modalReducer,
    flows: flowReducer,
    snapshot: snapshotReducer,
    tasks: taskReducer,
    calendar: calendarReducer,
    patients: patientsReducer,
    mobile: mobileReducer,
    billing: billingReducer,
    insurance: insuranceReducer,
    fileExplorer: fileExplorerReducer,
    labFlowsheet: labFlowsheetReducer,
    conversation: conversationReducer,
    [backendSlice.reducerPath]: backendSlice.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      backendSlice.middleware,
      localStorageMiddleware
    ),
  devTools: true
});

export const testingStore = configureStore({
  preloadedState: {
    auth: {
      isAuthenticated: true,
      sessionInfo: {
        is_superadmin: true,
        email: "provider@test.com",
        first: "Landon",
        is_admin: false,
        is_biller: false,
        is_ma: false,
        is_patient: false,
        is_provider: true,
        last: "Keough",
        permissions: [
          "appointment:read",
          "appointment:start",
          "appointment:write",
          "claim:read",
          "claim:write",
          "encounter:delete",
          "encounter:read",
          "encounter:submit",
          "encounter:write",
          "event:delete",
          "event:read",
          "event:write",
          "fax:read",
          "fax:send",
          "fax:write",
          "file:delete",
          "file:read",
          "file:write",
          "lab:read",
          "lab:send",
          "lab:write",
          "patient:list",
          "patient:read",
          "patient:transfer",
          "patient:write",
          "phrase:delete",
          "phrase:read",
          "phrase:write",
          "rx:write",
          "user:read",
          "user:write",
          "lab_flowsheet:read",
          "lab_flowsheet:write"
        ],
        practice_id: 1,
        suffix: null,
        title: null,
        user_id: 1,
        version: 1
      }
    }
  },
  reducer: {
    auth: testAuthSlice.reducer,
    dataGrid: gridReducer,
    tabMenu: tabReducer,
    drawer: drawerReducer,
    toast: toastReducer,
    modal: modalReducer,
    flows: flowReducer,
    snapshot: snapshotReducer,
    tasks: taskReducer,
    calendar: calendarReducer,
    patients: patientsReducer,
    mobile: mobileReducer,
    billing: billingReducer,
    insurance: insuranceReducer,
    fileExplorer: fileExplorerReducer,
    labFlowsheet: labFlowsheetReducer,
    conversation: conversationReducer,
    [backendSlice.reducerPath]: backendSlice.reducer,
    wizard: wizardReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(backendSlice.middleware),
  devTools: true
});

store.subscribe(() => {
  localStorage.setItem(
    AUTH_LOCAL_STORAGE_KEY,
    JSON.stringify(store.getState().auth)
  );
});

testingStore.subscribe(() => {
  localStorage.setItem(
    AUTH_LOCAL_STORAGE_KEY,
    JSON.stringify(testingStore.getState().auth)
  );
});

if (typeof window !== "undefined") {
  // @ts-ignore
  window.store = testingStore;
}

// using Next JS redux wrapper library  https://github.com/kirill-konshin/next-redux-wrapper
// Allows us to initialize client side state while rendering the page in the server side
// ref: https://medium.com/@noiseymur/next-redux-wrapper-tutorial-how-it-works-and-how-to-configure-it-properly-81f5e0b2ef8e
const makeStore = (context: Context) => store;
const makeTestStore = (context: Context) => testingStore;

export const wrapper = createWrapper<Store<RootState>>(makeStore);
export const testWrapper = createWrapper<Store<RootState>>(makeTestStore);

// Store Types
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
