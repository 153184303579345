import { createSlice } from "@reduxjs/toolkit";

import { FileId } from "@/store/services/file";

type initialState = {
  selectedFileIds: FileId[];
};

const initialState: initialState = {
  selectedFileIds: []
};

export const fileExplorerSlice = createSlice({
  name: "FileExplorer",
  initialState,
  reducers: {
    // immutable add remove depending on if the id is already in the list
    addRemoveSelectedFileId: (state, action) => {
      // if the id is already in the list, remove it
      if (state.selectedFileIds.includes(action.payload)) {
        state.selectedFileIds = state.selectedFileIds.filter(
          id => id !== action.payload
        );
      } else {
        state.selectedFileIds.push(action.payload);
      }
    },
    bulkRemoveSelectedFileIds: (state, action) => {
      state.selectedFileIds = state.selectedFileIds.filter(
        id => !action.payload.includes(id)
      );
    },
    resetSelectedFiles: () => initialState
  }
});

export const {
  addRemoveSelectedFileId,
  bulkRemoveSelectedFileIds,
  resetSelectedFiles
} = fileExplorerSlice.actions;

export default fileExplorerSlice.reducer;
