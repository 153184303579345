import { createSlice } from "@reduxjs/toolkit";

import { ConversationListItem } from "@/store/services/generated/conversation";

export type FilterType = "all" | "unread" | "archived";

type initialState = {
  displayedConversationId: ConversationListItem["conversation_id"] | null;
  selectedConversationIds: ConversationListItem["conversation_id"][];
  selectedInbox: "personal" | "practice";
  selectedFilter: FilterType;
};

const initialState: initialState = {
  displayedConversationId: null,
  selectedConversationIds: [],
  selectedInbox: "personal",
  selectedFilter: "all"
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    addSelectedConversationId: (state, { payload }) => {
      state.selectedConversationIds =
        state.selectedConversationIds.concat(payload);
    },
    removeSelectedConversationId: (state, { payload }) => {
      state.selectedConversationIds = state.selectedConversationIds.filter(
        id => id !== payload
      );
    },
    setSelectedConversationIds: (state, { payload }) => {
      state.selectedConversationIds = payload;
    },
    setSelectedInbox: (state, { payload }) => {
      state.selectedInbox = payload;
    },
    setSelectedFilter: (state, { payload }) => {
      state.selectedFilter = payload;
    },
    setDisplayedConversationId: (state, { payload }) => {
      state.displayedConversationId = payload;
    },
    resetConversationState: () => initialState,
  }
});

export const {
  addSelectedConversationId,
  removeSelectedConversationId,
  setSelectedConversationIds,
  setSelectedInbox,
  setSelectedFilter,
  setDisplayedConversationId,
  resetConversationState
} = conversationSlice.actions;

export default conversationSlice.reducer;
