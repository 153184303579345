import { createSlice } from "@reduxjs/toolkit";
import {v4} from "uuid"
import { STATUS_KEYS } from "@/globals/constants";

const MAX_ALERTS = 3; // max number of alerts to show at once

type ToastAlert = {
  id: number;
  message?: string;
  type: keyof typeof STATUS_KEYS;
  isVisible: boolean;
}

interface ToastTroughState {
    alerts: ToastAlert[]
}

const initialState: ToastTroughState = {
    alerts: []
};
export const toastSlice = createSlice({
  name: "toastTrough",
  initialState: initialState,
  reducers: {
    addAlertToToastTrough: (state, { payload }) => {
      const id = payload.id || v4();
      const newAlert = { ...payload, id };
      state.alerts = [
        ...state.alerts.slice(state.alerts.length >= MAX_ALERTS ? 1 : 0),
        newAlert,
      ];
    },
    removeAlertFromToastTrough: (state, { payload: id }) => {
        state.alerts = state.alerts.filter((alert) => alert.id !== id)
  },
  },
});

export const { addAlertToToastTrough, removeAlertFromToastTrough } = toastSlice.actions;

export default toastSlice.reducer;
